@font-face {
  font-family: 'Exo 2';
  font-weight: 100;
  src: local('Exo 2'), url(./fonts/Exo2-Thin-100.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 200;
  src: local('Exo 2'), url(./fonts/Exo2-ExtraLight-200.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 300;
  src: local('Exo 2'), url(./fonts/Exo2-Light-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 400;
  src: local('Exo 2'), url(./fonts/Exo2-Regular-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 500;
  src: local('Exo 2'), url(./fonts/Exo2-Medium-500.ttf) format('truetype');
}

@font-face {
  font-family: 'Exo 2';
  font-weight: 600;
  src: local('Exo 2'), url(./fonts/Exo2-SemiBold-600.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfb;
  min-height: 100vh;
  font-family: 'Exo 2', sans-serif;
}

.Mui-error {
  font-family: 'Exo 2', sans-serif !important;
}

a {
  text-decoration: none !important;
}

header {
  background-color: rgb(0, 159, 224);
}

@media only screen and (max-width: 600px) {
  .HideOnMobile {
    display: none;
  }

  .WetterAnkunftButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}